import SoyuzAnalytics from '@spotahome/soyuz-analytics';
import { DateRangeHandler } from '@spotahome/ui-library';

import * as mappings from './utils/mappings';

const { getDaysDiff, checkIfAvailable, getUserAge } = DateRangeHandler;

const USER_TENANT = 'tenant';

// Default instance to SoyuzAnalytics. Internally it is managing the client/server use case
export const CustomDimensionsManager = (instance = SoyuzAnalytics) => ({
  setCustomDimensions(cd) {
    try {
      instance.setCustomDimension(cd);
      if (cd.pageLanguage) {
        instance.set({ locale: cd.pageLanguage });
      }
    } catch (err) {
      console.error('Failed to call setCustomDimensions', { err });
    }
  },
  setCommonCustomDimensions({ userId, locale, pageType }) {
    try {
      const customDimensions = {
        userType: USER_TENANT,
        userRegistered: (!!userId).toString(),
        pageType,
        pageLanguage: locale
      };

      this.setCustomDimensions(customDimensions);
    } catch (err) {
      console.error('Failed to set custom dimensions', { err });
    }
  },
  setCurrencyCode(code) {
    try {
      instance.ecommerceManager.setCurrencyCode(code);
    } catch (err) {
      console.error('Failed to call setCurrencyCode', {
        err
      });
    }
  },
  clearListingCustomDimensions() {
    try {
      const clearedCd = {
        propertyType: undefined,
        propertyRoomsNumber: undefined,
        propertyHouseRules: undefined,
        propertyContractType: undefined,
        propertyCancellationType: undefined,
        propertyBillsIncluded: undefined,
        propertyMinimumStay: undefined,
        propertyChecked: undefined,
        propertyFeatures: undefined,
        propertySliderElementsOrder: undefined
      };
      this.setCustomDimensions(clearedCd);
    } catch (err) {
      console.error('Failed to clearListingCustomDimensions', { err });
    }
  },
  setListingCustomDimensions({
    listing,
    isLogged,
    locale,
    pageType,
    moveIn,
    moveOut
  }) {
    try {
      const cd = {
        userType: USER_TENANT,
        userRegistered: isLogged.toString(),
        pageType,
        pageLanguage: locale,
        propertyCity: listing.cityId,
        propertyType: listing.type,
        propertyRoomsNumber: Array.isArray(listing.roomsInApartment)
          ? listing.roomsInApartment.length
          : undefined,
        propertyHouseRules: mappings.mapHouseRules(listing.landlordRules),
        propertyContractType: listing.contractType,
        propertyCancellationType: listing.cancellationPolicy,
        propertyBillsIncluded: mappings.mapBillsIncluded(listing.bills),
        propertyMinimumStay: listing.minStay,
        propertyChecked: listing.checked.toString(),
        propertyFeatures: mappings.mapHouseFeatures({
          ...listing.features,
          ...listing.extraFeatures
        }),
        contractDurationTypesAllowed:
          listing.contractDurationTypesAllowed?.join(';'),
        propertyCountry: listing.countryCode,
        propertyPlan: listing.plan
      };
      if (moveIn && moveOut) {
        cd.moveInDate = moveIn;
        cd.moveOutDate = moveOut;
        cd.numNights = getDaysDiff(moveIn, moveOut);
        cd.daysInAdvance = getDaysDiff(Date.now(), moveIn);
        cd.propertyAvailable = checkIfAvailable(
          moveIn,
          moveOut,
          listing.unavailabilities
        ).toString();
      }
      this.setCustomDimensions(cd);
    } catch (err) {
      console.error('Failed to get common custom dimensions', { err });
    }
  },
  setBookNowButtonCustomDimensions({
    type,
    moveIn,
    moveOut,
    pricing,
    cityId,
    roomsInApartment,
    landlordRules,
    contractType,
    cancellationPolicy,
    bills,
    minStay,
    checked,
    features,
    extraFeatures,
    unavailabilities
  }) {
    try {
      const customDimensions = {
        moveInDate: moveIn,
        moveOutDate: moveOut,
        reservationFee: pricing.tenantFeeGross,
        contractValue: pricing.totalContractValue,
        numNights: getDaysDiff(moveIn, moveOut),
        daysInAdvance: getDaysDiff(Date.now(), moveIn),
        propertyAvailable: checkIfAvailable(
          moveIn,
          moveOut,
          unavailabilities
        ).toString(),
        propertyCity: cityId,
        propertyType: type,
        propertyRoomsNumber: Array.isArray(roomsInApartment)
          ? roomsInApartment.length
          : undefined,
        propertyHouseRules: mappings.mapHouseRules(landlordRules),
        propertyContractType: contractType,
        propertyCancellationType: cancellationPolicy,
        propertyBillsIncluded: mappings.mapBillsIncluded(bills),
        propertyMinimumStay: minStay,
        propertyChecked: checked.toString(),
        propertyFeatures: mappings.mapHouseFeatures({
          ...features,
          ...extraFeatures
        })
      };
      this.setCustomDimensions(customDimensions);
    } catch (err) {
      console.error('Failed to set listing selected dates custom dimensions', {
        err
      });
    }
  },
  setSearchCommonCustomDimensions({
    userId,
    city,
    moveIn,
    moveOut,
    locale,
    pageType
  }) {
    try {
      const customDimensions = {
        userType: USER_TENANT,
        userRegistered: `${!!userId}`,
        pageType,
        pageLanguage: locale
      };
      if (city) {
        customDimensions.propertyCity = city;
      }
      if (moveIn && moveOut) {
        customDimensions.moveInDate = moveIn;
        customDimensions.moveOutDate = moveOut;
        customDimensions.numNights = getDaysDiff(moveIn, moveOut);
        customDimensions.daysInAdvance = getDaysDiff(Date.now(), moveIn);
      }

      this.setCustomDimensions(customDimensions);
    } catch (err) {
      console.error('Failed to set custom dimensions', { err });
    }
  },
  specialFeaturesFromFilters(filters) {
    const specialFeatures = [];
    if (filters.noDeposit === 1) specialFeatures.push('noDeposit');
    if (filters.instantBooking === '1') specialFeatures.push('instantBooking');
    if (filters.verified === '1') specialFeatures.push('verified');
    if (filters.isBestChoice === '1') specialFeatures.push('isBestChoice');

    return specialFeatures.join(',');
  },
  poiFromFilters(filters) {
    if (filters.poiLat !== null && filters.poiLng !== null) {
      return `${filters.poiLat},${filters.poiLng}`;
    }

    return '';
  },
  setSearchQueryCustomDimensions(city, filters, resultsReturned) {
    try {
      const cd = {
        searchCity: city,
        searchMoveIn: filters.moveIn,
        searchMoveOut: filters.moveOut,
        searchFlexibleMoveIn: filters.flexibleMoveIn,
        searchFlexibleMoveOut: filters.flexibleMoveOut,
        searchPriceRange: filters.budget,
        searchPropertyTypes: filters.type.join(','),
        searchNumberBedrooms: filters.topology.join(','),
        searchSpecialFeatures: this.specialFeaturesFromFilters(filters),
        searchNeighborhoods: filters.areaId.join(','),
        searchBedTypes: filters.bed,
        searchNumberBathrooms: filters.bathrooms.join(','),
        searchPrivateBathroom: filters.bathrooms.join(','),
        searchFeatures: filters.features.join(','),
        searchPOI: this.poiFromFilters(filters),
        searchSortBy: filters.sortBy,
        searchPageNumber: filters.page,
        searchNumberResults: resultsReturned
      };

      this.setCustomDimensions(cd);
    } catch (err) {
      console.error('Failed to set search query custom dimensions', { err });
    }
  },
  setSearchMapCustomDimensions(
    northEastBoundary,
    southWestBoundary,
    numberResultsViewport
  ) {
    try {
      const cd = {
        searchMapNorthEastBoundary: northEastBoundary,
        searchMapSouthWestBoundary: southWestBoundary,
        searchNumberResultsViewport: numberResultsViewport
      };

      this.setCustomDimensions(cd);
    } catch (err) {
      console.error('Failed to set Search Map Custom Dimensions', {
        err
      });
    }
  },
  setSearchPropertyCustomDimensions(homecard, cityId) {
    try {
      const propertyCd = {
        propertyCity: cityId,
        propertyType: mappings.mapHomecardTypeToProductCategory(homecard.type),
        propertyChecked: homecard.checked.toString(),
        propertyRoomsNumber: Array.isArray(homecard.roomsInApartment)
          ? homecard.roomsInApartment.length
          : undefined,
        propertyFeatures: mappings.mapHouseFeatures(homecard.features)
      };
      this.setCustomDimensions(propertyCd);
    } catch (err) {
      console.error('Failed to track getPropertyCustomDimensions', {
        err
      });
    }
  },
  setTransactionalCustomDimensions({
    locale,
    user,
    pricing,
    listing,
    isLogged,
    pageType,
    tenantEmail,
    tenantPhone
  }) {
    const { tenantProfile } = user ?? {};
    const customDimensions = {
      userOccupation: tenantProfile.occupationType ?? '',
      userType: USER_TENANT,
      userRegistered: (!!isLogged).toString(),
      userGender: tenantProfile.gender ?? '',
      userNationality: tenantProfile.country ?? '',
      userAge: getUserAge(tenantProfile.birthday) ?? null,
      pageType,
      pageLanguage: locale,
      moveInDate: pricing.checkIn,
      moveOutDate: pricing.checkOut,
      propertyCity: listing.cityId,
      reservationFee: pricing.tenantFeeGross,
      reservationPrice: pricing.reservationPrice,
      contractValue: pricing.totalContractValue,
      numNights: getDaysDiff(pricing.checkIn, pricing.checkOut),
      daysInAdvance: getDaysDiff(Date.now(), pricing.checkIn),
      propertyType: listing.type,
      propertyAvailable: checkIfAvailable(
        pricing.checkIn,
        pricing.checkOut,
        listing.unavailabilities
      ).toString(),
      propertyRoomsNumber: listing.roomsInApartment.length,
      propertyHouseRules: mappings.mapHouseRules(listing.houseRules),
      propertyContractType: listing.contractType,
      propertyCancellationType: listing.cancellationPolicy,
      propertyBillsIncluded: mappings.mapBillsIncluded(listing.bills),
      propertyMinimumStay: listing.minStay,
      propertyChecked: listing.checked.toString(),
      propertyFeatures: mappings.mapHouseFeatures({
        ...listing.features,
        ...listing.extraFeatures
      }),
      propertyPlan: listing.plan,
      tenantEmail,
      tenantPhone
    };
    this.setCustomDimensions(customDimensions);
    this.setCurrencyCode(pricing.currencyCode);
  },
  sendEvent(...args) {
    instance.sendEvent(...args);
  },
  flush() {
    return instance.flush();
  }
});

export default {
  CustomDimensionsManager
};
